@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
	background-color: #ffffff;
	font-family: "Outfit", sans-serif;
}

.app-modal-body::-webkit-scrollbar {
	width: 8px;
}

/* Track */
.app-modal-body::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: white;
	box-shadow: inset 0 0 5px #fff;
}

/* Handle */
.app-modal-body::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background: gray;
	border: 1px solid white;
}

.app-modal-body::-webkit-scrollbar-thumb:hover {
	background: #707070;
}



a.detail-link {
	text-align: center;
	min-width: 64px;
	line-height: 1.75;
	border-radius: 4px;
	font-size: 14px;
	padding: 4px 5px;
	font-family: "Inter", sans-serif;
	color: rgb(30, 39, 49);
	font-weight: 500;
	display: inline-block;
	text-decoration: none;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
		color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

a.detail-link:hover {
	background-color: rgba(30, 39, 49, 0.04);
}

.profile-img {
	position: relative;
}

.profile-img img {
	padding: 10px;
	object-fit: cover;
	border-radius: 50%;
	/* border: 1px dashed #cdcdcd; */
}

.app-container {
	padding: 16px;
	background: white;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	box-shadow: 0 0 0 30px #f7f7f7 inset !important;
	-webkit-box-shadow: 0 0 0 30px #f7f7f7 inset !important;
}

.highlight {
	position: relative;
	display: inline-flex;
	width: 12px;
	height: 12px;
}

.highlight::after {
	content: "";
	position: absolute;
	left: 50%;
	top: 50%;
	width: 6px;
	height: 6px;
	transform: translate(-50%, -50%);
	box-sizing: border-box;
	border: none;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
	z-index: 2;
	border-radius: 50%;
}

.highlight.positive-medium::after {
	background: RGBA(42, 148, 70, 1);
	border: 1px solid RGBA(42, 148, 70, 1);
	-webkit-animation: pulse_positive-medium 1s ease infinite;
	animation: pulse_positive-medium 1s ease infinite;
}

.highlight.neutral-medium::after {
	background: RGBA(190, 190, 190, 1);
	border: 1px solid RGBA(190, 190, 190, 1);
}

.breadcrumb {
	padding: 25px 0;
}

.breadcrumb a {
	cursor: pointer;
	display: inline-block;
	transition: all 0.3s ease;
}

.breadcrumb a:hover,
.goback a:hover {
	color: #f5d312;
}

.breadcrumb a:first-child {
	margin-top: 5px;
	margin-right: 15px;
}

.goback a {
	color: #1e2731;
	display: flex;
	align-items: center;
	padding-top: 25px;
	cursor: pointer;
	text-decoration: none;
	transition: all 0.3s ease;
}

.form {
	padding: 35px 0;
	position: relative;
	border-radius: 16px;
	/* box-shadow: rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px; */
}

.MuiModal-root h3 {
	margin-top: 0;
}

.filters {
	padding: 16px 0;
	margin-bottom: 10px;
}

.pagination-list {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	justify-content: space-between;
}

.pagination-list p {
	color: #7b7b7b;
	margin: 0;
}

.MuiPagination-ul {
	justify-content: flex-end;
}

.distance-tag {
	top: 16px;
	right: 16px;
	height: 56px;
	color: #7b7b7b;
	padding: 8px 16px;
	line-height: 38px;
	position: absolute;
	border-radius: 4px;
	background-color: #fafafa;
}

.company-class p {
	color: #606060;
	margin-top: 6px;
	margin-bottom: 0;
}

.company-class .class-box {
	padding: 25px;
	cursor: pointer;
	position: relative;
	border-radius: 25px;
	transition: all 0.3s ease;
	border: 1px solid #f4f4f4;
}

.company-class .class-box.active {
	border-color: #1e2731;
	box-shadow: 0 0 30px -10px #cdcdcd;
}

.company-class .class-box .class-img {
	top: 24px;
	width: 35%;
	right: 10px;
	position: absolute;
}

.company-class .class-name {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	margin-bottom: 4px;
	white-space: nowrap;
}

.company-class .class-price {
	margin: 0;
	font-size: 22px;
	color: #606060;
	font-weight: 700;
	line-height: 24px;
	margin-bottom: 10px;
	white-space: nowrap;
}

.class-extras {
	display: flex;
	color: #1f2c34;
	align-items: center;
}

.class-extras p {
	margin: 0;
	margin-right: 12px;
	font-size: 14px;
	line-height: 18px;
	text-align: center;
	color: #606060;
}

.class-extras img {
	width: 16px;
	margin-right: 10px;
}
.checkout-card {
	padding: 25px;
	position: relative;
	border-radius: 8px;
	margin-bottom: 25px;
	background-color: #f8f8f8;
  }
  
  .checkout-card hr {
	border: 0;
	border-bottom: 1px solid #e0e0e0;
  }
  
  .checkout-card-heading {
	margin-top: 0;
	font-size: 20px;
	margin-bottom: 10px;
  }

  /* Payment Card Start Module Start */
.payment-card {
	padding: 0 10px;
	cursor: pointer;
	position: relative;
	border: 1px solid #ffffff;
	transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
	  rgb(145 158 171 / 12%) 0px 6px 20px -4px;
	transition: all 0.3s ease;
  }
  
  .payment-card.default {
	border: 1px solid #f5d312;
  }
  
  .payment-card:hover {
	background-color: #f8f8f8;
  }
  
  .payment-cards-heading {
	font-size: 16px;
	margin: 10px 0;
  }
  
  .ballance-card p {
	margin: 10px 0;
  }
  
  .payment-card-banner {
	display: flex;
	align-items: center;
	justify-content: space-between;
  }
  
  .payment-card-banner p {
	font-size: 18px;
	margin-right: 5px;
	word-break: break-all;
  }
  
  .payment-card-type-img img {
	width: 50px;
  }
@keyframes pulse_positive-medium {
	0% {
		-webkit-box-shadow: 0px 0px 0px 0px RGBA(42, 148, 70, 1);
		box-shadow: 0px 0px 0px 0px RGBA(42, 148, 70, 1);
	}

	100% {
		-webkit-box-shadow: 0px 0px 0px 5px transparent;
		box-shadow: 0px 0px 0px 5px transparent;
	}
}

@media (min-width: 600px) {
	.app-container {
		padding: 36px;
	}
}
